import Vue from 'vue';
import Vuex from 'vuex';
import MessageBubbleModel from '@/models/Components/MessageBubble/MessageBubbleModel';
import FormModel from '@/models/Objects/FormModel';
import { getCookie, createCookie } from '@/utilities/cookies';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        formHistory: Array<FormModel>(),
        messages: Array<MessageBubbleModel>(),
        isEditAvailable: false,
        loading: false,
        deviceIdentifierCookie: () => getCookie('deviceIdentifier'),
        sessionIdentifierCookie: () => getCookie('sessionIdentifier'),
        applicationIdentifierCookie: () => getCookie('applicationIdentifier'),
        applicantIdentifierCookie: () => getCookie('applicantIdentifier'),
        lastModifiedCookie: () => getCookie('lastModified'),
        deviceIdentifier: getCookie('deviceIdentifier'),
        sessionIdentifier: getCookie('sessionIdentifier'),
        applicationIdentifier: getCookie('applicationIdentifier'),
        applicantIdentifier: getCookie('applicantIdentifier'),
        lastModified: getCookie('lastModified'),
        trigger: undefined,
    },
    mutations: {
        updateFormHistory(state, formHistory: FormModel[]) {
            state.formHistory = formHistory;
        },
        addForm(state, form: FormModel) {
            state.formHistory.push(form);
        },
        updateMessages(state, messages: MessageBubbleModel[]) {
            state.messages = messages;
        },
        addMessage(state, message: MessageBubbleModel) {
            state.messages.push(message);
        },
        disableMessage(state, messageId: string) {
            const splitId = messageId.split('-');
            const index: number = parseInt(splitId[splitId.length - 1], 10);
            state.messages[index].disabled = true;
        },
        updateEditAvailableFlag(state, editAvailable: boolean) {
            state.isEditAvailable = editAvailable;
        },
        changeLoading(state) {
            state.loading = true;
        },
        updateDeviceIdentifier(state, deviceIdentifier) {
            createCookie('deviceIdentifier', deviceIdentifier, 315569520);
            state.deviceIdentifier = deviceIdentifier;
        },
        updateSessionIdentifier(state, sessionIdentifier) {
            createCookie('sessionIdentifier', sessionIdentifier, 1800);
            state.sessionIdentifier = sessionIdentifier;
        },
        updateApplicationIdentifier(state, applicationIdentifier) {
            createCookie('applicationIdentifier', applicationIdentifier, 1800);
            state.applicationIdentifier = applicationIdentifier;
        },
        updateApplicantIdentifier(state, applicantIdentifier) {
            createCookie('applicantIdentifier', applicantIdentifier, 1800);
            state.applicantIdentifier = applicantIdentifier;
        },
        updateLastModified(state, lastModified) {
            createCookie('lastModified', lastModified, 1800);
            state.lastModified = lastModified;
        },
        updateTrigger(state, trigger) {
            state.trigger = trigger;
        },
    },
    actions: {},
    modules: {},
});
